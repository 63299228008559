<template>
  <v-container fluid class="white--text">
    <v-row id="content">
      <v-col cols="12" class="px-1 py-0">
        <h1 v-html="$t('dictionaryWS.title')"></h1>
      </v-col>
      <v-col cols="12" sm="2" class="pl-2 pr-7 pb-0">
        <v-select
          v-model="language"
          :items="languages"
          item-text="title"
          item-value="locale"
          :label="$t('dictionaryWS.languageLabel')"
          @change="languageChanged"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="3" class="pl-2 pr-7 pb-0">
        <v-select
          v-model="section"
          :items="sections"
          item-text="title"
          item-value="id"
          :label="$t('dictionaryWS.sectionLabel')"
          @change="sectionChanged"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="7" class="pa-0 px-3 pb-0">
        <v-row>
          <v-col></v-col>
          <v-col cols="6" sm="3" class="px-1" align="right">
            <v-btn
              depressed
              tile
              v-html="$t('dictionaryWS.cancelButton')"
              class="primary white--text full-width"
              :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
              @click="cancel()"
            ></v-btn>
          </v-col>
          <v-col cols="6" sm="3" class="px-1" align="right">
            <v-btn
              depressed
              tile
              v-html="$t('dictionaryWS.saveButton')"
              class="primary white--text full-width"
              :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
              @click="save()"
            ></v-btn>
          </v-col>
          <v-col cols="6" sm="3" class="px-1" align="right">
            <v-btn
              depressed
              tile
              v-html="$t('dictionaryWS.reloadButton')"
              class="primary white--text full-width"
              :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
              @click="reload()"
            ></v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="px-1 pb-0">
        <v-textarea
          v-model="editedItem.content"
          auto-grow
          outlined
          :label="$t('dictionaryWS.contentLabel')"
          class="font-small"
        ></v-textarea>
      </v-col>
    </v-row>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      sections: [],
      section: null,
      item: {
        id: null,
        content: null,
      },
      editedItem: {
        id: null,
        content: null,
      },
      language: null,
      loading: false,
    };
  },

  computed: {
    ...mapGetters(["languages", "axiosSource"]),
  },

  methods: {
    setLanguage(locale) {
      const lang = this.languages.find((item) => item.locale === locale);

      if (lang) {
        this.language = lang;
      }
    },
    languageChanged(locale) {
      this.setLanguage(locale);
      this.getDictionaryWSSections(locale);
    },
    getDictionaryWSSections(locale) {
      this.loading = true;

      this.$store.dispatch("getDictionaryWSSections", locale).then(
        (response) => {
          this.loading = false;

          this.sections = response.items;

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);

          this.getDictionaryWS(this.language.locale, this.section);
        },
        (error) => {
          this.loading = false;

          this.sections = [];
          this.item = {};
          this.editedItem = {};

          let errorMessage;
          if (
            this.axiosSource &&
            this.axiosSource.token &&
            this.axiosSource.token.reason &&
            this.axiosSource.token.reason.message
          ) {
            errorMessage = this.axiosSource.token.reason.message;
          } else {
            errorMessage = error.message;
          }

          const notification = {
            show: true,
            result: false,
            message: errorMessage,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    sectionChanged(section) {
      this.getDictionaryWS(this.language.locale, section);
    },
    getDictionaryWS(locale, section) {
      if (!locale || !section) {
        return;
      }

      this.loading = true;

      this.$store
        .dispatch("getDictionaryWS", {
          locale: locale,
          section: section,
        })
        .then(
          (response) => {
            this.loading = false;

            this.item = {
              id: response.item.DictionaryWSID,
              content: response.item.Content,
            };

            this.editedItem = JSON.parse(JSON.stringify(this.item));

            const notification = {
              show: true,
              result: true,
              message: response.message,
            };

            this.$store.dispatch("showSnackbar", notification);
          },
          (error) => {
            this.loading = false;

            this.item = {};
            this.editedItem = {};

            let errorMessage;
            if (
              this.axiosSource &&
              this.axiosSource.token &&
              this.axiosSource.token.reason &&
              this.axiosSource.token.reason.message
            ) {
              errorMessage = this.axiosSource.token.reason.message;
            } else {
              errorMessage = error.message;
            }

            const notification = {
              show: true,
              result: false,
              message: errorMessage,
            };

            this.$store.dispatch("showSnackbar", notification);
          }
        );
    },
    cancel() {
      this.editedItem = JSON.parse(JSON.stringify(this.item));
    },
    save() {
      this.loading = true;

      this.$store
        .dispatch("updateDictionaryWS", {
          id: this.editedItem.id,
          content: this.editedItem.content,
        })
        .then(
          (response) => {
            this.loading = false;

            this.item = {
              id: response.item.DictionaryWSID,
              content: response.item.Content,
            };

            this.editedItem = JSON.parse(JSON.stringify(this.item));

            const notification = {
              show: true,
              result: true,
              message: response.message,
            };

            this.$store.dispatch("showSnackbar", notification);
          },
          (error) => {
            this.loading = false;

            this.item = {};
            this.editedItem = {};

            let errorMessage;
            if (
              this.axiosSource &&
              this.axiosSource.token &&
              this.axiosSource.token.reason &&
              this.axiosSource.token.reason.message
            ) {
              errorMessage = this.axiosSource.token.reason.message;
            } else {
              errorMessage = error.message;
            }

            const notification = {
              show: true,
              result: false,
              message: errorMessage,
            };

            this.$store.dispatch("showSnackbar", notification);
          }
        );
    },
    reload() {
      location.reload();
    },
  },

  created() {
    this.setLanguage("en");

    if (this.language) {
      this.getDictionaryWSSections(this.language.locale);
    }
  },
};
</script>

<style lang="scss" scoped>
.font-small {
  font-size: 14px;
}
</style>
